.MainPage {
    color: red;
    > div {
        margin-bottom: 50px;
    }
}

.Top {
    display: flex;
    gap: 80px;
    flex-direction: column;
    margin: -100px 0 80px;
}

.Bottom {
    display: flex;
    gap: 80px;
    flex-direction: column;
    margin: -170px 0 80px;

    > div {
        z-index: 1;
        &:first-child h2{
            color: #fff;
        }
    }
}

.News {
    display: flex;
    gap: 30px;

    > div {
        width: 50%;
    }
}

.Shops {
    color: #fff;
    min-height: 150px;

    &Info {
        max-width: 500px;
    }
}

.Selector {
    background: #ffffff;
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    z-index: 1;
    box-shadow: 0px 60px 40px rgb(0 13 37 / 15%);
    border-radius: 8px;
}


@media only screen and (max-width: 991px) {
    .News {
        display: flex;
        flex-direction: column;
        gap: 30px;
        > div {
            width: 100%;
        }
    }
    .Top {
        display: flex;
        gap: 60px;
        flex-direction: column;
        margin: -80px 0 60px;
    }

    .Bottom {
        display: flex;
        gap: 40px;
        flex-direction: column;
        margin: -200px 0 60px;
    }

    .Shops {
        min-height: 200px
    }
}


@media only screen and (max-width: 600px) {
    .Top {
        display: flex;
        gap: 30px;
        flex-direction: column;
        margin: -80px 0 30px;
    }

    .Bottom {
        display: flex;
        gap: 30px;
        flex-direction: column;
        margin: -230px 0 30px;
    }

    .Shops {
        min-height: 250px
    }
}

.BaltexModels {
    margin: 80px 0;
}

.BaltexGray {
    background-color: #212E38;
    padding: 80px;
    color: #fff;
}

.BaltexBloks {
    display: flex;
    flex-direction: column;
    gap: 80px;
}