.Shops {


  h2 {
    font-size: 40px;
    margin-bottom: 35px;
    color: #fff;
  }
}

.ShopsList {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;

  &Row_2 {
    grid-template-columns: 1fr 1fr;
  }

  &Row_3 {
    grid-template-columns: 1fr 1fr 1fr;
  }

  &Row_4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }


  &Baltex {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    .ShopsItem {
      background-color: #fff;
      border: none;
      border-radius: 0;
      color: #333;
    }

    .Name {
      max-width: 70%;
      font-size: 16px;
    }

    .Scheme {
      position: absolute;
      top: 30px;
      right: 30px;
      color: #939090;

      svg {
         display: none;
      }
    }
    .FastView {
      width: 100%;
      display: flex;
      justify-content: center;
      color: #0FB1B1;
      svg {
        path {
          fill: #0FB1B1;
        }
      }
    }
  }
}

.Padding {
  padding-bottom: 220px;
  display: block;
}

.ShopsItem {
  position: relative;
  padding: 30px;
  display: flex;
  gap: 15px;
  flex-direction: column;
  justify-content: space-between;
  background-color: #EEF0FC;
  border-radius: 10px;
  color: #9294A9;
  transition: 0.3s;
  min-height: 350px;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      box-shadow: 0px 20px 40px rgb(0 0 0 / 15%);
    }
  }
}


.Name {
  font-size: 20px;
  color: #2B2A29;
  margin-bottom: 15px;
  display: inline-block;
}

.Phone {
  margin-bottom: 15px;
}

.Time {
  margin-bottom: 15px;
}

.Types {
    display: flex;
    gap: 4px;
    span {
        border: 1px solid #D5D7E6;
        font-size: 12px;
        border-radius: 4px;
        padding: 6px 12px;
    }
}

.Bottom {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 991px) {
  .ShopsList {
 
  
    &Row_3 {
      grid-template-columns: 1fr 1fr;
    }
  
    &Row_4 {
      grid-template-columns: 1fr 1fr;
    }
  }
}


@media only screen and (max-width: 600px) {
  .Scheme {
    display: none;
  }
  .ShopsList {
 
  
    > a{
      padding: 15px;
    }
  }

  .Name {
    font-size: 14px;
  }

  .Types {
    flex-direction: column;
  }

  .Bottom {
    flex-direction: column;
    gap: 10px;
  }
}

.FastView {
  display: flex;
  gap: 10px;
  font-size: 14px;
  color: var(--main-theme);
  align-items: center;
}

.Scheme {
  display: flex;
  gap: 10px;
  font-size: 14px;
  color: var(--main-theme);
  align-items: center;
}